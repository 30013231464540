/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      image
      idImage
      idApproved
      profileImageApproved
      employed
      profileComplete
      active
      kids
      married
      deceased
      HAAFn
      HAAId
      DeathCertificate
      MHCFn
      MHCId
      AccessKey
      createdAt
      updatedAt
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        surname
        idNumber
        countryIssueId
        email
        phoneNumber
        address
        dateOfBirth
        gender
        image
        idImage
        idApproved
        profileImageApproved
        employed
        profileComplete
        active
        kids
        married
        deceased
        HAAFn
        HAAId
        DeathCertificate
        MHCFn
        MHCId
        AccessKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBeneficiaries = /* GraphQL */ `
  query GetBeneficiaries($id: ID!) {
    getBeneficiaries(id: $id) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      relationship
      married
      activeByUser
      active
      userId
      nationality
      createdAt
      updatedAt
    }
  }
`;
export const listBeneficiaries = /* GraphQL */ `
  query ListBeneficiaries(
    $filter: ModelBeneficiariesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeneficiaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        surname
        idNumber
        countryIssueId
        email
        phoneNumber
        address
        dateOfBirth
        gender
        relationship
        married
        activeByUser
        active
        userId
        nationality
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      message
      userId
      heading
      author
      read
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        userId
        heading
        author
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRecords = /* GraphQL */ `
  query GetRecords($id: ID!) {
    getRecords(id: $id) {
      id
      userId
      title
      category
      description
      company
      companyAddress
      referenceNumber
      website
      contact
      refPerson
      refContact
      refEmail
      amount
      notes
      privateNote
      videoUrl
      portalUrl
      portalUsername
      portalPassword
      country
      imageAssetUrl
      assetLocation
      assetLocRef
      beneficiaryId
      beneficiaryName
      beneficiarySurname
      createdAt
      updatedAt
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        title
        category
        description
        company
        companyAddress
        referenceNumber
        website
        contact
        refPerson
        refContact
        refEmail
        amount
        notes
        privateNote
        videoUrl
        portalUrl
        portalUsername
        portalPassword
        country
        imageAssetUrl
        assetLocation
        assetLocRef
        beneficiaryId
        beneficiaryName
        beneficiarySurname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
