/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      image
      idImage
      idApproved
      profileImageApproved
      employed
      profileComplete
      active
      kids
      married
      deceased
      HAAFn
      HAAId
      DeathCertificate
      MHCFn
      MHCId
      AccessKey
      createdAt
      updatedAt
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      image
      idImage
      idApproved
      profileImageApproved
      employed
      profileComplete
      active
      kids
      married
      deceased
      HAAFn
      HAAId
      DeathCertificate
      MHCFn
      MHCId
      AccessKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      image
      idImage
      idApproved
      profileImageApproved
      employed
      profileComplete
      active
      kids
      married
      deceased
      HAAFn
      HAAId
      DeathCertificate
      MHCFn
      MHCId
      AccessKey
      createdAt
      updatedAt
    }
  }
`;
export const createBeneficiaries = /* GraphQL */ `
  mutation CreateBeneficiaries(
    $input: CreateBeneficiariesInput!
    $condition: ModelBeneficiariesConditionInput
  ) {
    createBeneficiaries(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      relationship
      married
      activeByUser
      active
      userId
      nationality
      createdAt
      updatedAt
    }
  }
`;
export const updateBeneficiaries = /* GraphQL */ `
  mutation UpdateBeneficiaries(
    $input: UpdateBeneficiariesInput!
    $condition: ModelBeneficiariesConditionInput
  ) {
    updateBeneficiaries(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      relationship
      married
      activeByUser
      active
      userId
      nationality
      createdAt
      updatedAt
    }
  }
`;
export const deleteBeneficiaries = /* GraphQL */ `
  mutation DeleteBeneficiaries(
    $input: DeleteBeneficiariesInput!
    $condition: ModelBeneficiariesConditionInput
  ) {
    deleteBeneficiaries(input: $input, condition: $condition) {
      id
      firstName
      surname
      idNumber
      countryIssueId
      email
      phoneNumber
      address
      dateOfBirth
      gender
      relationship
      married
      activeByUser
      active
      userId
      nationality
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      message
      userId
      heading
      author
      read
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      message
      userId
      heading
      author
      read
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      message
      userId
      heading
      author
      read
      createdAt
      updatedAt
    }
  }
`;
export const createRecords = /* GraphQL */ `
  mutation CreateRecords(
    $input: CreateRecordsInput!
    $condition: ModelRecordsConditionInput
  ) {
    createRecords(input: $input, condition: $condition) {
      id
      userId
      title
      category
      description
      company
      companyAddress
      referenceNumber
      website
      contact
      refPerson
      refContact
      refEmail
      amount
      notes
      privateNote
      videoUrl
      portalUrl
      portalUsername
      portalPassword
      country
      imageAssetUrl
      assetLocation
      assetLocRef
      beneficiaryId
      beneficiaryName
      beneficiarySurname
      createdAt
      updatedAt
    }
  }
`;
export const updateRecords = /* GraphQL */ `
  mutation UpdateRecords(
    $input: UpdateRecordsInput!
    $condition: ModelRecordsConditionInput
  ) {
    updateRecords(input: $input, condition: $condition) {
      id
      userId
      title
      category
      description
      company
      companyAddress
      referenceNumber
      website
      contact
      refPerson
      refContact
      refEmail
      amount
      notes
      privateNote
      videoUrl
      portalUrl
      portalUsername
      portalPassword
      country
      imageAssetUrl
      assetLocation
      assetLocRef
      beneficiaryId
      beneficiaryName
      beneficiarySurname
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecords = /* GraphQL */ `
  mutation DeleteRecords(
    $input: DeleteRecordsInput!
    $condition: ModelRecordsConditionInput
  ) {
    deleteRecords(input: $input, condition: $condition) {
      id
      userId
      title
      category
      description
      company
      companyAddress
      referenceNumber
      website
      contact
      refPerson
      refContact
      refEmail
      amount
      notes
      privateNote
      videoUrl
      portalUrl
      portalUsername
      portalPassword
      country
      imageAssetUrl
      assetLocation
      assetLocRef
      beneficiaryId
      beneficiaryName
      beneficiarySurname
      createdAt
      updatedAt
    }
  }
`;
