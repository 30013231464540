import { View, Text, Dimensions, TouchableOpacity, FlatList, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
const {width, height} = Dimensions.get('screen');
import { useNavigation, useRoute } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons'; 
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from './src/graphql/queries';



const Results = () => {

    const navigation = useNavigation();
    const route = useRoute().params;
    const [resultss ,setResultss] = useState('');

 

    const fetchBeneficiaries = async()=>{

 
  
         const allBenes = await API.graphql({ query: queries.listUserData, variables: 
            {
                filter :   { idNumber: {eq: 
                                (route?.idNum)
                            }}
            } });  

          const benesList = allBenes.data.listUserData.items
          setResultss(benesList)

      }

      useEffect(() => {
        fetchBeneficiaries();
      }, [])
      


    const renderList = ({item})=> {
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('Details', {item: item})} 
            style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15}}
            >
                <Image source={{uri: item.image}}  
                style={{height: 60, width: 60, borderRadius: 60, resizeMode: 'contain'}}/>
                <View style={{marginLeft: 20}}>
                    <Text style={{fontWeight: 'bold', color: '#0f2838'}}>{item.firstName} {item.surname}</Text> 
                    <Text style={{fontSize: 12, color: '#0f2838'}}>{item.idNumber}</Text>
                </View>

                <View style={{position: 'absolute', width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', bottom: 10, right: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <AntDesign name="arrowright" size={18} color="white" />
                </View>
                <View style={{position: 'absolute', right: 15, top: 5}}>
                    <Text style={{fontSize: 11, fontWeight: 'bold', color: 'red'}}>{item?.deceased ? `Deceased` : ``}</Text>
                </View>
            </TouchableOpacity>
        ) 
    }



  return (
    <View>
       <View style={{height: height / 3, backgroundColor: '#fcfcfc', width: height/ 3, borderRadius: 10, padding: 30}}> 
       <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 10, marginTop: 10}}>Searching : {route?.idNum}</Text>
            <FlatList 
                data={resultss}
                renderItem={renderList}
               
            />

      </View>
    </View>
  )
}

export default Results