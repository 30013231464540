import { initializeApp } from "firebase/app";
import {getStorage, ref} from "firebase/storage";



  const firebaseConfig = {
  apiKey: "AIzaSyCpD9mLEFIm5elYPqo52997KvB371a-NRQ",
  authDomain: "firstenergy-a1753.firebaseapp.com",
  projectId: "firstenergy-a1753",
  storageBucket: "firstenergy-a1753.appspot.com",
  messagingSenderId: "96690193422",
  appId: "1:96690193422:web:95555b5d140fe6020ca85f"
};

const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)