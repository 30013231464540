import { View, Text, Dimensions, TouchableOpacity, TextInput, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
const {width, height} = Dimensions.get('screen');
import { useNavigation } from '@react-navigation/native';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUserData } from './src/graphql/queries';





const Plug = () => {


    const [choose,setChoose] = useState('Main');
    const [emailAddress, setEmailAddress] = useState('');
    const [password ,setPassword] = useState('');
    const [idNumber ,setIdNumber] = useState('');
    const [userInfo, setUserInfo] = useState();

    const navigation = useNavigation();


    const checkAuth = async()=>{

      const userAuthenticated =  await Auth.currentAuthenticatedUser();
      const userSub = userAuthenticated.attributes.sub;
  
      try{
        await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
        const userd = ef.data.getUserData;
  
        setUserInfo(userd)
  
      }).catch((ee)=> {
        console.error(ee)
      })
  
  } catch (e){
    console.error(e)
    setChoose('Main'),
    console.log('Not authenticated')
  }
  
    }




  useEffect(() => {
    checkAuth()
  }, [userInfo])




    const signout = async()=> {

        try {
          await Auth.signOut().then((e)=> {
             setChoose('SignOUt') 
          })
        } catch (error) {
          console.error(error)
        }

    }

    if(choose === 'SignOUt'){
      return(
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Text>You have been Logged out :)</Text>
        </View>
      )
    }


  return (
    <View style={{flex: 1, backgroundColor: '#f2f2f2', alignItems: 'center', justifyContent: 'center'}}>
      <View style={{height: 80, width: width, backgroundColor: 'white', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 30, position: 'absolute', top: 0}}>
        
        {/* logo */}
        <View>
          <Text>Logo</Text>
        </View>

        {/* options */}

        <View style={{flexDirection: 'row', paddingHorizontal: 20}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View>
              <Image source={{uri: userInfo?.image}} style={{height: 40, width: 40, borderRadius: 60}}/>
            </View>
            <Text style={{marginLeft: 15}}>Hey, {userInfo?.firstName}</Text>

            <TouchableOpacity onPress={()=> signout()} style={{marginLeft: 30}}>
            <Text style={{fontWeight: 'bold', color: 'blue'}}>LogOut</Text>
          </TouchableOpacity>
          </View>

          
          
        </View>

      </View>

      <View style={{height: height / 3, backgroundColor: '#fcfcfc', width: height/ 3, borderRadius: 10, padding: 30}}> 
        <Text>Welcome to certain. Please enter the account holder's ID Number</Text>


        <View style={{marginTop: 30}}>
                <Text style={{marginBottom: 15}}>ID Number</Text>
                <TextInput placeholder='90021547845**' value={emailAddress} onChangeText={setEmailAddress} style={{width: '80%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
              </View>

              <View style={{marginTop: 20}}>
                <TouchableOpacity onPress={()=> navigation.navigate('Results', {idNum: emailAddress})} style={{height: 50, width: '80%', backgroundColor: '#1b85d1', alignItems: 'center', justifyContent: 'center'}}>
                  <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Find Account</Text>
                </TouchableOpacity>
              </View>

      </View>
      

    </View>
  )
}

export default Plug