import { View, Text, Dimensions, TouchableOpacity, TextInput, FlatList, Image, ActivityIndicator } from 'react-native'
import React, {useState, useEffect} from 'react'
const {width, height} = Dimensions.get('screen');
import { useNavigation, useRoute } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons'; 
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from './src/graphql/queries';
import * as mutations from './src/graphql/mutations';
import { getUserData } from './src/graphql/queries';
import * as DocumentPicker from 'expo-document-picker';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './config';

const Details = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [repoAcc, setRepoAcc] = useState(false)
    const [deceased, setDeceased] = useState(false)
    const [decOpt, setDecOpt] = useState(false)
    const [userInfo, setUserInfo] = useState();
    const [spinning, setSpinning] = useState(false);
    const [imgUrlOne, setImgUrlOne] = useState();

    
    const getUserInfo = async()=>{

        try{
            await API.graphql(graphqlOperation(getUserData, { id: route.item.id})).then((ef)=> {
            const userd = ef.data.getUserData
      
            setUserInfo(userd)
      
          }).catch((ee)=> {
            console.error(ee)
          })
      
      } catch (e){
        console.error(e)
        
      }

    }

    useEffect(() => {
        getUserInfo()
      }, [userInfo])

       
    const settingDeceased = async()=> {

        setSpinning(true)

        const authdata = await Auth.currentAuthenticatedUser()
        const authedId = authdata.attributes.sub

        const inputData ={
            id: route.item.id,
            deceased: true,
            HAAFn: authedId      
        }

        try {
            await API.graphql({query: mutations.updateUserData, variables: {input: inputData}}).then((ee)=> {
                setDeceased(true), 
                setDecOpt(false),
                setSpinning(false)
              })
        } catch (error) {
            console.error(error)
        }
        

    }

    const pickingDocument = async()=> {
        let result = await DocumentPicker.getDocumentAsync({
            type: '&ast;/*'
        })

        console.log(result)
        uploadDocument(result)

    }

    const uploadDocument = async(pickerResult)=> {

        setSpinning(true)

        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

            console.log(pickerResult.uri)
            setSpinning(false)
        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'pdf/document',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrlOne(downloadURL)
            uploadToDatabase(downloadURL)
            console.log('d')
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }

    const uploadToDatabase = async(url)=> {

        setSpinning(true)

        const inputData = {
            id: route.item.id,
            DeathCertificate: url
        }


        try {
            await API.graphql({query: mutations.updateUserData, variables: {input: inputData}}).then((ee)=> {
                
                setSpinning(false)
                navigation.navigate('ID')
              })
        } catch (error) {
            console.error(error)
        }




        alert(url)

    }

  return (
    <View>
       <View style={{height: height / 2, backgroundColor: '#fcfcfc', width: height/ 2, borderRadius: 10, padding: 30}}> 
            
           <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image source={{uri: route.item.image}} style={{height: 150, width: 150, borderRadius: 100}}/>
                <View style={{marginLeft: 15}}>
                    <Text style={{fontSize: 20, fontWeight: 'bold', color: '#0f2838'}}>{route.item.firstName} {route.item.surname} </Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>{route.item.idNumber}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>{route.item.email || `info@sundaycreatives.co.za`}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>Registered : {String(route.item.createdAt).slice(0,10) || `--`}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>Updated : {String(route.item.updatedAt).slice(0,10) || `--`}</Text>
                </View>
           </View>
           <View style={{marginTop: 20}}>
                <Text style={{fontWeight: 'bold', alignSelf: 'center'}}>Options</Text>
           </View>

           <View style={{marginTop: 25,flexDirection: 'row', justifyContent: 'space-evenly'}}>
                
                <View style={{width: '40%'}}>
                {userInfo?.deceased ? 
                <View>
                    {userInfo?.DeathCertificate ? 
                    <View style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}>
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Certificate Uploaded</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name='check' size={18} color="white" />
                        </View>  
                    
                    </View>
                    :
                    <TouchableOpacity onPress={()=> pickingDocument()}
                    style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                    >
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Upload Death Certificate</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name="arrowright" size={18} color="white" />
                        </View>  
    
                        <View style={{position: 'absolute', top: 30, left: 30}}>
                            {spinning && <ActivityIndicator size={'large'} color={'red'} />}
                        </View>  
                        </TouchableOpacity>
                    }
                    

                </View>
                 : 
                <TouchableOpacity onPress={()=> setDecOpt(true)} 
                style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: 16}}>Set Deceased</Text>
                    <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                        <AntDesign name="arrowright" size={18} color="white" />
                    </View>    
                </TouchableOpacity> } 
                </View>
               

                <TouchableOpacity onPress={()=> setRepoAcc(true)}
                style={{height: 80, width : '40%', marginBottom: 10, backgroundColor: 'rgba(247, 88, 40, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: 16}}>Report Account</Text>
                    <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                        <AntDesign name="arrowright" size={18} color="white" />
                    </View>    
                </TouchableOpacity>
           </View>


      </View>

      {repoAcc && 

        <View style={{position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%'}}>
            <View style={{height: '40%', backgroundColor: '#fcfcfc', width: '40%', borderRadius: 10, padding: 30}}>
                <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'justify'}}>Are you sure you want to report this account ?</Text>

                <View style={{marginTop: 15}}>
                    <TouchableOpacity onPress={()=> setRepoAcc(false)} style={{height: 40, width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Yes, I'm Sure</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> setRepoAcc(false)} style={{height: 40, width: '100%', marginTop: 15, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>No, Cancel</Text>
                    </TouchableOpacity>
                </View>

            </View>
    
        </View>

        }

            {decOpt && 

            <View style={{position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%'}}>
                <View style={{height: '40%', backgroundColor: '#fcfcfc', width: '40%', borderRadius: 10, padding: 30}}>
                    <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'justify'}}>Are you sure you want to set as deceased t ?</Text>

                    <View style={{marginTop: 15}}>
                        <TouchableOpacity onPress={()=> { settingDeceased()}} style={{height: 40, width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                            <View style={{position: 'absolute', left: 5}}>
                                {spinning && <ActivityIndicator color={'#fcfcfc'} size={'small'} />}
                            </View>
                            <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Yes, I'm Sure</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> { setDecOpt(false)}} style={{height: 40, width: '100%', marginTop: 15, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                            <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>No, Cancel</Text>
                        </TouchableOpacity>
                    </View>

                </View>

            </View>

            }

    </View>
  )
}

export default Details